module.exports = [{
      plugin: require('/Users/admin/phpdev/schody-gatsby/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ff33cc","theme_color":"#ff33cc","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('/Users/admin/phpdev/schody-gatsby/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-71489736-1","head":true,"anonymize":true},
    },{
      plugin: require('/Users/admin/phpdev/schody-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
